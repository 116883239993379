// IBM Plex
$font-prefix: '../font/IBMPlexMono';

// latin1
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Mono'), local('IBMPlexMono'),
    url('#{$font-prefix}/IBMPlexMono-Regular-Latin1.woff2')
      format('woff2');
  unicode-range: 'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02';
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local('IBM Plex Mono Italic'), local('IBMPlexMono-Italic'),
    url('#{$font-prefix}/IBMPlexMono-Italic-Latin1.woff2')
      format('woff2');
  unicode-range: 'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+20AC, U+2122, U+2212, U+FB01-FB02';
}

// latin2
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Mono'), local('IBMPlexMono'),
    url('#{$font-prefix}/IBMPlexMono-Regular-Latin2.woff2')
      format('woff2');
  unicode-range: 'U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02';
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local('IBM Plex Mono Italic'), local('IBMPlexMono-Italic'),
    url('#{$font-prefix}/IBMPlexMono-Italic-Latin2.woff2')
      format('woff2');
  unicode-range: 'U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02';
}

// latin3
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local('IBM Plex Mono Italic'), local('IBMPlexMono-Italic'),
    url('#{$font-prefix}/IBMPlexMono-Italic-Latin2.woff2')
      format('woff2');
  unicode-range: 'U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02';
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local('IBM Plex Mono Italic'), local('IBMPlexMono-Italic'),
    url('#{$font-prefix}/IBMPlexMono-Italic-Latin2.woff2')
      format('woff2');
  unicode-range: 'U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02';
}


$font-family-monospace: 'IBM Plex Mono', sfmono-regular, menlo, monaco, consolas,
  'Liberation Mono', 'Courier New', monospace;

body {
  font-family: $font-family-monospace;
}

header a,
.navbar-brand {
  font-style: italic;
}

header a {
  font-size: $font-size-lg;
}
