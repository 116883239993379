$enable-flex: true;
$enable-shadows: true;
$input-btn-line-height: 1.35;
$christa-wiener-red: #b41e24;
$primary: $christa-wiener-red;
$breadcrumb-bg: transparent;
$list-group-bg: transparent;
$list-group-border-color: #222;
$pagination-border-color: transparent;

// slick
$slick-loader-path: "../img/";
