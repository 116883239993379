/*!
 * Bootstrap 4 Thema für Christa Wiener
 * Krzysztof Jeziorny 2017; Acat GmbH
*/
@import 'variables';
@import './node_modules/bootstrap/scss/bootstrap'; // Bootstrap 4
@import './node_modules/slick-carousel/slick/slick';
@import './node_modules/slick-carousel/slick/slick-theme';
@import 'typography';
@import 'style';
