// Styles
body {
  font-weight: 400;
  margin-top: 1rem;
  height: calc(100vh - 1rem);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  hyphens: auto;
}

// content
h2 {
  margin-bottom: 1.5rem;
  text-align: center;
}

a {
  &:hover {
    text-decoration: none;
  }
}

img {
  @include img-fluid;
}

.icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;

  a & {
    fill: $link-color;
  }

  .disabled a & {
    fill: $custom-select-disabled-color;
  }
}

// header

.navbar {
  border-radius: 2rem;
}

@include media-breakpoint-between(lg, xl) {
  .nav-link {
    margin-left: 1rem;
  }
}

.bg-light {
  border: 1px solid $gray-200;
}

header {
  margin-bottom: 2rem;
}

.navbar-brand {
  position: relative;
  z-index: 1;
}

@include media-breakpoint-between(md, xl) {
  .navbar-brand {
    padding-left: 3rem;

    &::before {
      margin-left: -3.6rem;
      display: block;
      content: '';
      position: absolute;
      width: 3rem;
      height: 3rem;
      margin-top: -0.6rem;
      z-index: -1;
      background: $red;
      border-radius: 50%;
      background-size: contain;
    }
  }
}

.navbar-toggler {
  border-color: $red;
  border-radius: 50%;
}

// werk detailseite
.detail {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: $h4-font-size;
  }

  figure img {
    max-height: 77vh;
    margin: 1rem auto;

    @extend .img-thumbnail;
  }
}

.breadcrumb {
  background-color: transparent;
  border-bottom: 2px solid $gray-200;
  border-radius: 0;
}

// werk karte
.card {
  position: relative;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: transform, opacity;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgb(0 0 0 / 30%);
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:hover {
    transform: scale(1.05, 1.05);
    box-shadow: 0 15px 45px -5px rgb(10 16 34 / 15%);
    z-index: 1;

    &::after {
      opacity: 1;
    }
  }
}

.card-columns {
  @include media-breakpoint-only(md) {
    column-count: 3;
  }

  @include media-breakpoint-only(sm) {
    column-count: 2;
  }
}

// carousel
.slider {
  figure {
    display: flex !important;
    flex-direction: column;
    max-height: 100vh;
    justify-content: center;
    align-items: center;
  }

  a {
    z-index: 1;
    position: relative;

    img {
      max-height: 77vh;
      width: auto !important;
      margin: 0 auto;

      @extend .img-thumbnail;
    }
  }

  figcaption {
    position: relative;
    z-index: 2;
    font-style: italic;
  }

  .slick-prev::before {
    color: $gray-600;
  }

  .slick-next::before {
    color: $gray-600;
  }
}

// Über mich
.ueber-mich {
  section {
    display: flex;
    display: grid;
    justify-content: left;
    grid-template-columns: 50% 30%;
    grid-gap: 1rem;

    figure img,
    figure source {
      @extend .img-thumbnail;
    }
  }

  .lead {
    span img {
      vertical-align: middle;
      width: 20px;
      width: 1.5rem;
      height: 20px;
      height: 1.5rem;
      margin-top: -0.3rem;
    }

    span {
      &::before {
        content: '';
        display: inline-block;
        background-color: $red;
        width: 1.4rem;
        height: 1.4rem;
        line-height: 1;
        border-radius: 50%;
        margin-top: -0.2rem;
        margin-right: 0.3rem;
        vertical-align: middle;
      }
    }
  }
}

footer {
  @extend footer;
  @include make-container;

  margin: 2rem auto;
  font-size: 11px;
}

// pagination
.page-item.disabled .page-link {
  border-color: transparent;
}
